.ql-toolbar.ql-snow {
    border: none !important;
    border-bottom: 1px solid #e0e0e0 !important;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
    border: none !important;
}

.discord-icon, .mail-icon {
    cursor: pointer;
}